<template>
  <section>
    <section class="flex items-center gap-3 xl:justify-between justify-end">
      <section
        class="flex ml-auto xl:ml-0 justify-between gap-2 bg-white rounded-lg md:gap-3 w-auto px-0"
      >
        <section
          class="rtl:flex-row-reverse order-1 sm:order-2 items-center border xl:h-11 md:h-10 h-9 border-primary-gray-300 rounded-lg flex relative px-3.5"
        >
          <button class="focus:outline-none w-5 h-5" @click="clickPrevDate">
            <Icon :color="dynamicColor" icon="chevronLeft" height="20" width="20" />
          </button>
          <div>
            <QDatePicker
              :key="filterView"
              v-model="dateModelBind"
              :is-future-date-disabled="isFutureDateDisabled"
              custom-class="right-0
              before:right-12"
              class="order-2 md:order-1"
              :picker="filterBy"
              :options="{
                saveLastState: false,
              }"
              :actions-attr="{
                style: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '12px',
                },
                class: 'pt-4 mt-4 border-t border-primary-grey rtl:flex-row-reverse font-inter',
              }"
              :actions="[
                {
                  text: $t(`dateTime.Cancel`),
                  handler: 'cancel',
                  attr: {
                    class:
                      ' w-[8.875rem] h-[2.5rem] border border-primary-gray-300 rounded-lg text-xs md:text-sm font-semibold text-primary-gray-700 shadow-button',
                  },
                },
                {
                  text: $t(`dateTime.Apply`),
                  handler: 'apply',
                  attr: {
                    class:
                      ' w-[8.875rem] h-[2.5rem] text-sm text-white bg-primary-purple-600 font-semibold rounded-lg shadow-button',
                  },
                },
              ]"
              @update:modelValue="applyNewDateFilter"
            >
              <section class="flex h-10 calenderMobile custom-calender font-inter">
                <button
                  class="h-full mx-auto md:ml-0 md:mr-0 md:px-3 items-center md:text-sm font-semibold text-primary-gray-500 focus:outline-none px-1"
                  :class="textSizeClass"
                >
                  <span v-if="filterView === DATE_DURATION.WEEK">
                    <span v-if="isConciseView" class="flex whitespace-nowrap">
                      {{ formatDateRange(dateRange.startDate, dateRange.endDate) }}
                    </span>
                    <span v-else class="flex">
                      <Icon icon="calender" height="20" width="20" class="mr-1.5 hidden sm:flex" />
                      <span :class="dynamicTextColor">
                        {{ formatDateFromLocalToUTC(dateRange.startDate, 'MMM D, YYYY') }}
                      </span>
                      <span class="mx-2.5">-</span>
                      <Icon icon="calender" height="20" width="20" class="mr-1.5 hidden sm:flex" />
                      <span :class="dynamicTextColor">
                        {{ formatDateFromLocalToUTC(dateRange.endDate, 'MMM D, YYYY') }}
                      </span>
                    </span>
                  </span>

                  <span v-else class="flex items-center gap-1.5">
                    <Icon icon="calender" height="20" width="20" />
                    <span :class="dynamicTextColor">
                      {{ getStringFormat() }}
                    </span>
                  </span>
                </button>
              </section>
            </QDatePicker>
          </div>
          <button
            class="focus:outline-none w-5 h-5"
            :disabled="isFutureMonthDisabled()"
            @click="clickNextDate"
          >
            <Icon :color="dynamicColor" icon="chevronRight" height="20" width="20" />
          </button>
        </section>
      </section>
      <section
        v-if="filterOptions.length"
        class="customCalenderSelect flex justify-end sm:pr-0 sm:pl-0"
      >
        <UiSingleSelect
          :model-value="filterView"
          label="label"
          reduce="value"
          hide-un-select-icon="true"
          hide-title="true"
          no-error="true"
          select-box-classes="xl:h-11 md:h-10 h-9"
          :options="filterOptions"
          class="flex-1"
          @change="setFilter"
        />
      </section>
    </section>
    <slot name="extra-filters"></slot>
  </section>
</template>

<script>
import Icon from '@components/icons/icon.vue'

import QDatePicker from '@/src/lib/j-date-picker/QDatePicker.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'

import { formatDateString } from '@utils/format-date'
import {
  formatDateFromLocalToUTC,
  formatDateRange,
  isDateInFuture,
} from '@src/utils/moment.util.js'
import { DATE_DURATION } from '@src/constants/date-time-constants.js'

export default {
  name: 'CalendarActionsWrapper',
  components: {
    Icon,
    UiSingleSelect,
    QDatePicker,
  },
  props: {
    calendarDate: {
      type: [String, Date, Object],
      default: () => new Date(),
    },
    isFutureDateDisabled: {
      type: Boolean,
      default: false,
    },
    isConciseView: {
      type: Boolean,
      default: false,
    },
    filterView: {
      type: String,
      default: 'Month',
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['selectDateRange', 'selectDate', 'setFilter', 'applyFilter'],
  data() {
    return {
      DATE_DURATION,
      dateModelBind: new Date(),
      attendanceTranslation: 'attendanceTranslation',
      dateRange: {
        startDate: null,
        endDate: null,
      },
      filterOptions: [],
    }
  },
  computed: {
    dynamicColor() {
      return (this.isPreviousDate && !this.isCurrentDate) ||
        (this.isNextDate && !this.isCurrentDate)
        ? 'primary-purple-700'
        : 'primary-gray-700'
    },
    textSizeClass() {
      return this.getStringFormat() ? 'text-xs' : 'text-xxs'
    },
    dynamicTextColor() {
      return this.isCurrentDate
        ? 'text-primary-purple-700 whitespace-nowrap'
        : 'text-primary-gray-500 whitespace-nowrap'
    },
    filterBy() {
      if (this.filterView === DATE_DURATION.MONTH) return 'month'
      else if (this.filterView === DATE_DURATION.WEEK) return 'date-range'
      else return 'date'
    },
    datePickerSettings() {
      const settingsProps = {
        ranges: false,
        singleDatePicker: true,
      }

      if (this.filterView === DATE_DURATION.WEEK) {
        settingsProps.ranges = {}
        settingsProps.singleDatePicker = false
      }

      return settingsProps
    },
    dateRangeFormat() {
      let format = 'dd MMMM yyyy'

      switch (this.filterView) {
        case 'Day':
          format = 'dd MMMM yyyy'
          break
        default:
          format = 'MMMM yyyy'
          break
      }

      return format
    },

    isCurrentDate() {
      let status = false
      switch (this.filterView) {
        case DATE_DURATION.MONTH: {
          const providedMonth = formatDateString(this.calendarDate, 'MMMM yyyy')
          const currentMonth = formatDateString(new Date(), 'MMMM yyyy')
          if (providedMonth === currentMonth) status = true
          break
        }
        case DATE_DURATION.WEEK: {
          const newStartDate = new Date(this.calendarDate.startDate)
          const newDate = new Date().toString().slice(0, 15)
          newStartDate.setDate(newStartDate.getDate())

          if (newStartDate.toString().slice(0, 15) === newDate) status = true
          break
        }
        case 'Day': {
          const providedDay = formatDateString(this.calendarDate, 'dd MMMM yyyy')
          const currentDay = formatDateString(new Date(), 'dd MMMM yyyy')
          if (providedDay === currentDay) status = true
          break
        }
      }

      return status
    },

    isPreviousDate() {
      let status = false
      switch (this.filterView) {
        case DATE_DURATION.MONTH: {
          const providedMonthObj = new Date(this.calendarDate)
          const providedMonth = {
            month: providedMonthObj.getMonth(),
            year: providedMonthObj.getFullYear(),
          }

          const currentMonthObj = new Date()
          const currentMonth = {
            month: currentMonthObj.getMonth(),
            year: currentMonthObj.getFullYear(),
          }

          if (providedMonth.year < currentMonth.year) {
            status = true
          } else {
            if (providedMonth.year === currentMonth.year) {
              if (providedMonth.month < currentMonth.month) {
                status = true
              } else {
                status = false
              }
            } else {
              status = false
            }
          }

          break
        }
        case 'Day': {
          const providedMonth = new Date(this.calendarDate)
          const currentMonth = new Date()

          if (providedMonth < currentMonth) status = true
          break
        }
      }

      return status
    },

    isNextDate() {
      let status = false
      switch (this.filterView) {
        case DATE_DURATION.MONTH: {
          const providedMonthObj = new Date(this.calendarDate)
          const providedMonth = {
            month: providedMonthObj.getMonth(),
            year: providedMonthObj.getFullYear(),
          }

          const currentMonthObj = new Date()
          const currentMonth = {
            month: currentMonthObj.getMonth(),
            year: currentMonthObj.getFullYear(),
          }

          if (providedMonth.year > currentMonth.year) {
            status = true
          } else {
            if (providedMonth.year === currentMonth.year) {
              if (providedMonth.month > currentMonth.month) {
                status = true
              } else {
                status = false
              }
            } else {
              status = false
            }
          }
          break
        }
        case 'Day': {
          const providedMonth = new Date(this.calendarDate)
          const currentMonth = new Date()

          if (providedMonth > currentMonth) status = true
          break
        }
      }

      return status
    },
  },
  watch: {
    calendarDate: {
      immediate: true,
      handler(dt) {
        if (this.filterView === DATE_DURATION.WEEK) {
          const currentDate = new Date()

          this.dateRange.startDate = dt?.startDate || currentDate
          this.dateModelBind.start = dt?.startDate || currentDate

          if (dt?.startDate && dt?.endDate && dt?.startDate === dt?.endDate) {
            const maxCurrentDateRange = new Date(dt?.startDate)
            maxCurrentDateRange.setDate(maxCurrentDateRange.getDate() + 6)

            this.dateRange.endDate = maxCurrentDateRange
            this.dateModelBind.end = maxCurrentDateRange
          } else {
            const maxCurrentDateRange = new Date()
            maxCurrentDateRange.setDate(maxCurrentDateRange.getDate() + 6)

            this.dateRange.endDate = dt?.endDate || maxCurrentDateRange
            this.dateModelBind.end = dt?.endDate || maxCurrentDateRange
          }
        } else {
          this.dateRange.startDate = dt
          this.dateRange.endDate = dt
          this.dateModelBind = { start: dt, end: dt }
        }
      },
    },
  },
  mounted() {
    this.filterOptions = this.filters.map((filter) => {
      return {
        label: this.$t(`dateTime.${filter}`),
        value: filter,
      }
    })
  },
  methods: {
    formatDateRange,
    formatDateFromLocalToUTC,
    isFutureMonthDisabled() {
      if (this.isFutureDateDisabled) return isDateInFuture(this.dateRange.startDate)
    },

    applyNewDateFilter() {
      if (this.filterView === DATE_DURATION.WEEK) {
        this.$emit('selectDateRange', {
          startDate: this.dateModelBind.start,
          endDate: this.dateModelBind.end,
        })
      } else {
        this.$emit('selectDate', this.dateModelBind.start ?? this.dateModelBind)
      }

      this.$emit('applyFilter')
    },

    getStringFormat() {
      return this.translateDate(formatDateString(this.dateRange.startDate, this.dateRangeFormat))
    },
    translateDate(inputDate) {
      var dates = inputDate.split(' ').filter((dateValue) => dateValue !== '')

      var translatedDate = dates.map((date) => {
        if (/\d/.test(date) === true) {
          date = date
            .split('')
            .map((arabNum) => this.$t(`rightBar.${arabNum}`))
            .join('')
          return date
        } else {
          // return this.$t(`dateTime.${date.slice(0,3)}`)
          return this.filterView === DATE_DURATION.MONTH
            ? this.$t(`dateTime.${date}`)
            : this.$t(`dateTime.${date.slice(0, 3)}`)
        }
      })
      return translatedDate.join(' ')
    },

    setFilter(filterView) {
      this.$emit('setFilter', filterView)
    },

    selectDate(dt) {
      this.dateRange = dt

      if (this.filterView === DATE_DURATION.WEEK) {
        this.$emit('selectDateRange', dt)
      } else {
        this.$emit('selectDate', dt.startDate ?? dt)
      }
    },

    applyFilter(dt) {
      this.selectDate(dt)
      this.$emit('applyFilter')
    },

    formatDateString,
    clickNextDate() {
      switch (this.filterView) {
        case DATE_DURATION.MONTH: {
          const newDate = new Date(this.calendarDate)

          newDate.setMonth(newDate.getMonth() + 1)

          this.$emit('selectDate', newDate)

          this.$emit('applyFilter')
          break
        }
        case DATE_DURATION.WEEK: {
          const newStartDate = new Date(this.calendarDate.startDate)
          const newEndDate = new Date(this.calendarDate.endDate)

          newStartDate.setDate(newStartDate.getDate() + 6)
          newEndDate.setDate(newEndDate.getDate() + 6)
          this.selectDate({ startDate: newStartDate, endDate: newEndDate })

          this.$emit('applyFilter')
          break
        }
        case 'Day': {
          const newDate = new Date(this.calendarDate)

          newDate.setDate(newDate.getDate() + 1)

          this.$emit('selectDate', newDate)

          this.$emit('applyFilter')
          break
        }
      }
    },
    clickPrevDate() {
      switch (this.filterView) {
        case DATE_DURATION.MONTH: {
          const newDate = new Date(this.calendarDate)

          newDate.setMonth(newDate.getMonth() - 1)

          this.$emit('selectDate', newDate)

          this.$emit('applyFilter')
          break
        }
        case DATE_DURATION.WEEK: {
          const newStartDate = new Date(this.calendarDate.startDate)
          const newEndDate = new Date(this.calendarDate.endDate)

          newStartDate.setDate(newStartDate.getDate() - 6)
          newEndDate.setDate(newEndDate.getDate() - 6)

          this.selectDate({ startDate: newStartDate, endDate: newEndDate })

          this.$emit('applyFilter')
          break
        }
        case 'Day': {
          const newDate = new Date(this.calendarDate)

          newDate.setDate(newDate.getDate() - 1)

          this.$emit('selectDate', newDate)

          this.$emit('applyFilter')
          break
        }
      }
    },
  },
}
</script>
