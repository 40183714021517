// https://date-fns.org/docs/format
import format from 'date-fns/format'
import moment from 'moment'
export default function formatDate(date) {
  return format(date, 'MMM do, yyyy')
}

export const formatDateString = (date, dateStringFormat = 'yyyy-MM-dd') => {
  return format(new Date(Date.parse(date)), dateStringFormat)
}
export const utcOffsetZero = (date, format) => {
  if (format) return moment(date, format).utcOffset(0, true)
  else return moment(date).utcOffset(0, true)
}
